<div class="table-responsive table-flush">
    <table class="table table-hover table-sticky-actions" [class.dim]="!complete">
        <thead>
            <tr>
                <th orderby [key]="crud?.options?.queryKey" [field]="'nbr'" style="width: 50px">#</th>
                <th style="min-width: 40px"></th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'noOfIncompleteTasks'">Tasks</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'location.id'" style="width: 100%; min-width: 350px">Location</th>
                <th class="text-nowrap">Assigned</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'location.stream'">Stream</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'priority'">Priority</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'dipl'">DIPL</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'status'">Status</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'slaBreach'">SLA</th>
                <th>Hours</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'report.created'">Reported</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'dateCompleted'">Completed</th>
                <th orderby [key]="crud?.options?.queryKey" [field]="'dateVerified'">Verified</th>

                <th class="text-end">Actions</th>
            </tr>
        </thead>
        <tbody *ngIf="response; else loading">
            <ng-container *ngFor="let item of response.data; let i = index">
                <ngxh6-offline-indicator [model]="item"></ngxh6-offline-indicator>

                <tr>
                    <td class="align-middle">{{ item.nbr }}</td>
                    <td class="align-middle"><ngxh6-usericon size="small" [user]="item.allocatedTo"></ngxh6-usericon></td>
                    <td class="align-middle"><app-task-progress [job]="item"></app-task-progress></td>
                    <td class="align-middle small">
                        <a [routerLink]="'/jobs/' + item.id">{{ item.location.string }} </a>
                    </td>
                    <td class="align-middle">
                        <ngxh6-usericon size="small" [max]="2" [user]="item.tasksAllocatedTo"></ngxh6-usericon>
                        <span class="badge rounded-pill bg-warning" *ngIf="!item.tasksAllocatedTo?.length">N/A</span>
                    </td>
                    <td class="align-middle small text-nowrap">{{ item.location.streamLabel }}</td>
                    <td class="align-middle">{{ item.priorityLabel }}</td>
                    <td class="align-middle"><app-job-dipl [job]="item"></app-job-dipl></td>
                    <td class="align-middle"><app-job-status [job]="item"></app-job-status></td>
                    <td class="align-middle text-nowrap"><app-job-sla [job]="item"></app-job-sla></td>
                    <td class="align-middle text-center"><app-job-time [job]="item"></app-job-time></td>
                    <td class="align-middle text-center">{{ item.reportedDate | str2Date | date: 'shortDate' }}</td>
                    <td class="align-middle text-center">{{ item.dateCompleted ? (item.dateCompleted | str2Date | date: 'shortDate') : '' }}</td>
                    <td class="align-middle text-center">{{ item.dateVerified ? (item.dateVerified | str2Date | date: 'shortDate') : '' }}</td>

                    <td class="align-middle text-end">
                        <a class="btn btn-gray" [routerLink]="'/jobs/' + item.id">Edit</a>
                    </td>
                </tr>
            </ng-container>
        </tbody>

        <ng-template #loading><tbody table-loader [cols]="15" [actions]="true" [error]="crud.error"></tbody></ng-template>
    </table>
</div>
