<span [ngbTooltip]="tt" container="body" *ngIf="!job.referredToDipl">
    <ng-container *ngIf="!job.dateCompleted; else na">
        <div class="badge bg-danger" *ngIf="job.slaBreach | str2Date | amIsBefore: (null | str2Date); else notBreach">
            {{ job.slaBreach | str2Date | amTimeAgo }}
        </div>

        <ng-template #notBreach>
            <div class="badge bg-success" *ngIf="job.slaBreach | str2Date | amIsAfter: (null | str2Date | amAdd: 1:'week'); else nearBreach">
                {{ job.slaBreach | str2Date | amTimeAgo }}
            </div>
        </ng-template>

        <ng-template #nearBreach>
            <div class="badge bg-warning text-dark">
                {{ job.slaBreach | str2Date | amTimeAgo }}
            </div>
        </ng-template>
    </ng-container>

    <ng-template #na>
        <ng-container *ngIf="(job.slaBreach | str2Date) >= (job.dateCompleted | str2Date); else breach">
            <div class="badge bg-success">
                <i class="fa fa-check"></i>
                {{ job.slaBreach | str2Date | amDifference: (job.dateCompleted | str2Date):'days' }} days
            </div>
        </ng-container>

        <ng-template #breach>
            <div class="badge bg-danger">
                <i class="fa fa-times"></i>
                {{ job.slaBreach | str2Date | amDifference: (job.dateCompleted | str2Date):'days' }} days
            </div>
        </ng-template>
    </ng-template>
</span>
<ng-template #tt>{{ job.slaBreach | str2Date | date: 'short' }}</ng-template>
