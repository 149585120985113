import { ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { Observable } from 'rxjs';
import { TaskInterface } from '../_generated/api_interfaces/api/task.interface';

export function syncConfigFactory(user: UserServiceInterface, api: ApiServiceInterface): object {
  return {
    /**
     * Add any endpoints that need to be synced offline here
     */
    endpoints: [],

    /**
     * Tasks are Observable[] and will be executed in sequence. You can perform any actions you want here.
     * The observable should return string[] of endpoints that will be added to the offline sync queue.
     */
    tasks: [
      new Observable<string[]>(observer => {
        // Tasks can access services provided via DI
        // console.log(user);

        // Endpoints returned by the observer will be added to the sync queue
        observer.next(['/api/v1/locations?pagination=false']);
        observer.next(['/api/v1/users/assignable?pagination=false']);
        observer.next(['/api/v1/users/allocatable?pagination=false']);
        observer.next(['/api/v1/users/staff?pagination=false']);

        // Tasks must complete
        observer.complete();
      }),

      // Get pending tasks
      new Observable<string[]>(observer => {
        api.get({ url: '/api/v1/tasks', useCache: true, refresh: true }).subscribe((res: ReturnType<TaskInterface['apiTasksGetCollection']>) => {
          if (res.type !== 'api') return;
          res.data.forEach(task => observer.next([`/api/v1/job_cards/${task.jobCard.id}`]));
          observer.complete();
        });
      }),
    ],
  };
}
