export const role_hierarchy: { [key: string]: string | string[] } = {
  // changes here need to be reflected in backend: security.yaml
  ROLE_ADMIN: 'ROLE_APP_MANAGER',
  ROLE_APP_MANAGER: 'ROLE_MANAGER',
  ROLE_MANAGER: ['ROLE_HMO', 'ROLE_REPORTER'],
  ROLE_HMO: 'ROLE_CAN_VIEW_JOBS',
  ROLE_REPORTER: 'ROLE_CAN_REPORT',
  ROLE_RESTRICTED_REPORTER: 'ROLE_CAN_REPORT',

  ROLE_CAN_REPORT: 'ROLE_USER',
  ROLE_CAN_VIEW_JOBS: 'ROLE_USER',
};
