import {ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import { NgControl } from '@angular/forms';
import {FormServiceInterface, ModalsServiceInterface, OfflineServiceInterface, SfFormCollectionComponent, SfFormComponent} from '@hutsix/ngxh6';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { finalize, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-task-collection',
    templateUrl: './sf-form-task-collection.component.html',
    styleUrls: ['./sf-form-task-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormTaskCollectionComponent extends SfFormCollectionComponent implements OnInit {
    @ViewChildren('sfFormComponent') forms!: QueryList<SfFormComponent>;

    selectedTask?: string;
    completeTasks: Set<string> = new Set();

    constructor(
        public cdRef: ChangeDetectorRef,
        public ngControl: NgControl,
        @Inject('ModalsService') public modalService: ModalsServiceInterface,
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('OfflineService') public offlineService: OfflineServiceInterface,
        public route: ActivatedRoute,
        public appRef: ApplicationRef,
        public scroller: ViewportScroller,
    ) {
        super(cdRef, ngControl, modalService);
        this.scroller.setOffset([0, 100]);
    }

    ngOnInit() {
        super.ngOnInit();

        this.route.queryParams.subscribe(params => {
            this.selectedTask = params.task ?? undefined;
        });
    }

    toggleComplete(i: number): void {
        if (!this.offlineService.isConnected) {
            return;
        }

        this.inputValue[i].complete = !this.inputValue[i].complete;
        this.forms.toArray()[i].onInputChange(this.inputValue[i]);
    }

    writeValue(value: any) {
        super.writeValue(value);
        setTimeout(() => this.scroller.scrollToAnchor(this.selectedTask), 500);
    }

    async isCompleteOffline(id: string): Promise<any> {
        const data = await this.offlineService
            .getOfflinePostById(id)
            .pipe(map(res => !!res))
            .toPromise();

        if (data) {
            this.completeTasks.add(id);
        } else {
            this.completeTasks.delete(id);
        }

        return data;
    }

    canCompleteOffline(item: any): boolean {
        return !this.offlineService.isConnected && item.id === this.selectedTask && !item.complete;
    }

    completeOffline(id: string): void {
        this.offlineService.getOfflinePostById(id).subscribe(res => {
            this.formService.openFormModal('job_card_complete', id, res?.data ?? { id, date: new Date() }).subscribe(
                () => this.isCompleteOffline(id).then(() => this.cdRef.detectChanges()),
                () => this.isCompleteOffline(id).then(() => this.cdRef.detectChanges()),
                () => this.isCompleteOffline(id).then(() => this.cdRef.detectChanges()),
            );
        });
    }
}
