import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiErrorResponse, ApiResponse, ApiServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    public signin: any = {
        email: null,
        password: null,
    };

    public redirect?: string;
    public error?: string;
    public loading = false;

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        @Inject('UserService') private userService: UserServiceInterface,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
    ) {}

    public doSignin(): void {
        this.loading = true;
        this.api.post({ url: '/api/user/login_check', data: this.signin, skipAuth: true, displayErrors: false }).subscribe(
            (res: ApiResponse<any>) => {
                this.error = null;
                this.userService
                    .loginUser(res.data)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(() => {
                        this.userService.reloadRoute(this.redirect || '/');
                    });
            },
            (err: ApiErrorResponse<any>) => {
                this.loading = false;
                this.error = err.error?.error ? `${err.error.error.code} ${err.error.error.message}` : err.error?.message;
                this.cdRef.detectChanges();
            },
        );
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => (this.redirect = params.returnUrl || null));

        this.userService.watch.pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
            if (user.loggedIn) this.userService.reloadRoute(this.redirect || '/');
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
