<span
    [ngbTooltip]="tt"
    class="badge"
    *ngIf="!job.referredToDipl"
    [class.bg-success]="job.hoursActual <= job.hoursEstimate"
    [class.bg-danger]="job.hoursActual > job.hoursEstimate"
>
    {{ job.hoursActual | hours }} / {{ job.hoursEstimate | hours }}
</span>

<ng-template #tt>
    <span class="text-nowrap">Estimate: {{ job.hoursEstimate | hours }}</span>
    <br />
    <span class="text-nowrap">Actual: {{ job.hoursActual | hours }}</span>
</ng-template>
