import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CrudComponent, CrudTableComponentInterface } from '@hutsix/ngxh6';
import { JobCardInterface } from '../../../../_generated/api_interfaces/api/jobCard.interface';

@Component({
    selector: 'app-job-table',
    templateUrl: './job-table.component.html',
    styleUrls: ['./job-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTableComponent implements CrudTableComponentInterface {
    @Input() public response: ReturnType<JobCardInterface['apiJobCardsGetCollection']>;
    @Input() public crud: CrudComponent;
    @Input() public complete: boolean;
}
