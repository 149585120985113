import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ValueAccessorBase } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-hours',
    templateUrl: './sf-form-hours.component.html',
    styleUrls: ['./sf-form-hours.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormHoursComponent extends ValueAccessorBase {
    public type: string = 'number';

    public inputValue: any = {
        hour: '0',
        min: '0',
    };

    transformValueViewToModel(value: any): any {
        const h = Number(value.hour);
        const m = Number(value.min);

        return h + m / 60;
    }

    extractValueFromFormView(): any {
        return this.transformValueModelToView(this.view?.vars?.value);
    }

    transformValueModelToView(value: any): any {
        const num: any = {
            hour: Math.floor(value).toString() ?? '0',
            min: (Math.ceil(((value % 1) * 100) / 25) * 15).toString() ?? '0',
        };

        this.inputValue = num;
        return num;
    }
}
