import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CrudComponent, CrudTableComponentInterface } from '@hutsix/ngxh6';
import { ReportInterface } from '../../../../_generated/api_interfaces/api/report.interface';

@Component({
    selector: 'app-report-table',
    templateUrl: './report-table.component.html',
    styleUrls: ['./report-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTableComponent implements CrudTableComponentInterface {
    @Input() public response: ReturnType<ReportInterface['apiReportsGetCollection']>;
    @Input() public crud: CrudComponent;
    @Input() public complete: boolean;
}
