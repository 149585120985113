export const environment = {
    production: true,
    env: 'production',
    apiUrl: 'https://api.ramp.cdrc.app',
    whitelistedDomains: ['api.ramp.cdrc.app'],
    blacklistedRoutes: [],
    // TODO: update it back to imagixDomain when imagix works properly
    imgixDomain: 'https://cdrc-app-assets.s3.ap-southeast-2.amazonaws.com',
    vapidPubKey: 'BMxnj8mJKGxvYktBKR7C-NWLZBpMtC59-50gGj6AhC0RbFmF8ArKnQvGYtJEqRfUvQ_BLcSqFD5enNdoMmARJ7o',
};
