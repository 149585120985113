<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <ngxh6-tabs
            class="mt-5"
            *ngIf="navItems"
            [containerClass]="'tab-container p-4 mb-3'"
            [navItems]="navItems"
            [content]="tabContent"
            [key]="'form-tab'"
            [small]="true"
        ></ngxh6-tabs>

        <ng-template #tabContent let-id="id">
            <ng-container *ngIf="id === 'basic'">
                <fieldset ngModelGroup="basic" #basicForm="ngModelGroup">
                    <sf-form name="form1" [view]="form.view" [(ngModel)]="form.model" [fields]="['name']"></sf-form>
                </fieldset>
            </ng-container>

            <ng-container *ngIf="id === 'account'">
                <div class="row">
                    <div class="col-6">
                        <sf-form
                            #accountForm
                            name="form"
                            rowClasses="col-12"
                            [view]="form.view"
                            [(ngModel)]="form.model"
                            [fields]="['email', 'username', 'roles', 'locked']"
                        ></sf-form>
                    </div>
                    <div class="col-6">
                        <sf-form
                            #accountForm
                            name="form3"
                            rowClasses="col-12"
                            [view]="form.view"
                            [(ngModel)]="form.model"
                            [fields]="['plainPassword']"
                        ></sf-form>
                    </div>
                </div>
            </ng-container>
        </ng-template>

        <hr *ngIf="!options.sticky_actions && options.actions_separator" />
        <ngxh6-form-actions
            [form]="form"
            [options]="options"
            [formRef]="formRef"
            [valid]="formRef.valid"
            [saving]="saving"
            (cancel)="cancel()"
            (delete)="delete()"
        ></ngxh6-form-actions>
    </form>
</ng-container>

<ng-template #loadingOrError><ngxh6-loading-or-error [error]="error"></ngxh6-loading-or-error></ng-template>
