import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CrudItemComponent, NavItem } from '@hutsix/ngxh6';
import { JobCardInterface } from '../../../../../_generated/api_interfaces/api/jobCard.interface';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-job-sla',
    templateUrl: './job-sla.component.html',
    styleUrls: ['./job-sla.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobSlaComponent {
    @Input() public job: Partial<{ slaBreach: string; dateCompleted?: string; referredToDipl: boolean }>;
}
