<div class="w-100 text-center mt-1" style="min-width: 70px" *ngIf="job.noOfTasks">
    <div class="progress" container="body" [ngbTooltip]="job.noOfTasks - job.noOfIncompleteTasks + ' of ' + job.noOfTasks + ' tasks complete'">
        <div
            class="progress-bar progress-bar-striped bg-success"
            role="progressbar"
            [ngStyle]="{ width: ((job.noOfTasks - job.noOfIncompleteTasks) / job.noOfTasks) * 100 + '%' }"
        ></div>
    </div>
</div>

<span class="badge bg-light text-dark" container="body" *ngIf="!job.noOfTasks">None</span>
