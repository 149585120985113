<div class="input-group">
    <select class="form-select hours" aria-label="Hours" [(ngModel)]="inputValue.hour" (ngModelChange)="onInputChange()" [disabled]="view.vars.disabled">
        <option *ngFor="let e of [].constructor(100); let i = index" [value]="i">{{ i }}</option>
    </select>

    <span class="input-group-text">:</span>

    <select class="form-select mins" aria-label="Minutes" [(ngModel)]="inputValue.min" (ngModelChange)="onInputChange()" [disabled]="view.vars.disabled">
        <option value="0">00</option>
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="45">45</option>
    </select>
</div>
