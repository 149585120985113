import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { CrudComponent, CrudTableComponentInterface, OfflineServiceInterface } from '@hutsix/ngxh6';
import { ReportInterface } from '../../../../_generated/api_interfaces/api/report.interface';
import { TaskInterface } from '../../../../_generated/api_interfaces/api/task.interface';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-task-table',
    templateUrl: './task-table.component.html',
    styleUrls: ['./task-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskTableComponent implements CrudTableComponentInterface {
    @Input() public response: ReturnType<TaskInterface['apiTasksGetCollection']>;
    @Input() public crud: CrudComponent;
    @Input() public complete: boolean;

    completeTasks: Set<string> = new Set();

    constructor(@Inject('OfflineService') public offlineService: OfflineServiceInterface) {}

    async isCompleteOffline(id: string): Promise<any> {
        const data = await this.offlineService
            .getOfflinePostById(id)
            .pipe(map(res => !!res))
            .toPromise();

        if (data) {
            this.completeTasks.add(id);
        } else {
            this.completeTasks.delete(id);
        }

        return data;
    }
}
